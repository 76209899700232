
  .calculators_container{
    margin-bottom: 45px;
}

.title h1{
    font-size: 44px;
    font-family: "Graphik Bold";
    padding-top: 35px;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
}